export const getDomain = (url) => {
	try {
		if (!url) return;
		const href = new URL(url);
		if (!href || !href.hostname) return null;
		const { hostname } = href;
		let domain = hostname;
		const parts = hostname.split('.').reverse();
		if (parts != null && parts.length > 1) {
			domain = parts[1] + '.' + parts[0];
			if (hostname.toLowerCase().indexOf('.co.uk') != -1 && parts.length > 2) {
				domain = parts[2] + '.' + domain;
			}
		}
		return domain;
	} catch (e) {
		return;
	}
};

export const shuffle = (array) => {
	let currentIndex = array.length;
	let temporaryValue;
	let randomIndex;
	// While there remain elements to shuffle...
	while (0 !== currentIndex) {
		// Pick a remaining element...
		randomIndex = Math.floor(Math.random() * currentIndex);
		currentIndex -= 1;
		// And swap it with the current element.
		temporaryValue = array[currentIndex];
		array[currentIndex] = array[randomIndex];
		array[randomIndex] = temporaryValue;
	}
	return array;
};
