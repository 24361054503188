/* eslint-disable no-unreachable */
/* eslint-disable no-lone-blocks */
import React, { useRef, useEffect } from 'react';
import Style from './addAddress.module.css';
// import back from 'assets/back.png'

const AddAddress = ({ toogleUI, linkwallet, message, activeTab, error, inputHandler }) => {
  const addressInput = useRef();

  useEffect(() => {
    if (activeTab === 1 && addressInput !== null) {
      addressInput.current.focus();
    } else {
      addressInput.current.blur();
    }
  }, [activeTab]);

  return (
    <div className={Style.wrapper}>
      <div className={Style.backHeader} onClick={toogleUI}>
        {/* <img className={Style.back} src={back} alt='' style={{width:'15px',height:'15px'}}/> */}
        <span style={{ border: activeTab === 0 ? '1px solid #c4b5c9' : '1px solid white' }}>Go Back</span>
      </div>
      <div>
        <input className={Style.inputField} placeholder='Paste Your Address' onChange={(e) => { inputHandler(e); }} ref={addressInput} style={{ border: activeTab === 1 ? '1px solid #c4b5c9' : '1px solid white' }} />
        <div className={Style.btnWrapper}><div className={Style.addButton} onClick={linkwallet} style={{ border: activeTab === 2 ? '1px solid #c4b5c9' : '1px solid white' }}>Add</div></div>
      </div>
      {error.length ? <span style={{ marginTop: 100, color: "red", fontSize: "0.8rem" }}>{error}</span> : <></>}
      {message.length ? <span style={{ marginTop: 100, color: "green", fontSize: "0.8rem" }}>{message}</span> : <></>}
    </div>
  );
};

export default AddAddress;