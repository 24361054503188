import React, { useState, useEffect } from 'react';
import { auth, firestore } from 'utils/firebase';
import { getDoc, doc } from 'firebase/firestore';
import Header from 'components/Header/Header';
import PageSpinner from 'components/Spinner/PageSpinner';
import Card from 'components/Cards/Card';
import Styles from './rewardHistoryStyles.module.css';

function RewardHistoryPage(props) {
    const [rewardHistory, setRewardHistory] = useState([]);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const user = auth.currentUser;
        if (user != null) {
            const userDataRef = doc(firestore, 'users', user.uid);
            getDoc(userDataRef).then((doc) => {
                if (doc.exists() && doc.data().rewardHistory) {
                    setRewardHistory(doc.data().rewardHistory);
                    setLoading(false);
                } else {
                    setLoading(false);
                }
            });
        } else {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        document.addEventListener('keydown', onKeyDown);
        return () => { document.removeEventListener('keydown', onKeyDown); };
    }, []);

    const onKeyDown = (e) => {
        if (e.key === 'Backspace' || e.key === 'ArrowRight') {
            props.history.goBack();
        }
    };

    const backHandler = () => {
        props.history.push('/history');
    };

    return (
        <>
            <Header title='Rewards History' back={true} backHandler={backHandler} />
            {/* <div className="container">
            </div> */}
            {loading && <PageSpinner />}
            {rewardHistory.length > 0 && rewardHistory.map((data, i) => (
                <Card
                    leftValue={data.type}
                    rightValue={data.points}
                    lowerValue={data.earnedAt.seconds}
                    rightText={'Points'}
                    icon={data.type}
                    key={i} />
            ))}
            {
                !loading && rewardHistory.length === 0 &&
                <div className={Styles.container}>
                    No Rewards History
                </div>
            }
        </>
    );
}

export default RewardHistoryPage;