import Style from 'components/Header/headerStyles.module.css';
import { useHistory } from 'react-router-dom';
import logo from 'assets/logo_white.png';

function Header({ title, refresh = false, back = false, backHandler = false }) {
    const refreshHandler = () => {
        window.location.reload();
    };
    const history = useHistory();

    const handleGoToHome = () => {
        history.goBack();
    };

    return (
        <div className={Style.HeaderLayout}>
            <div className={`container ${Style.HeaderContainer}`}>
                {
                    back && backHandler
                        ?
                        <div className={Style.HeaderIcons} onClick={backHandler}>
                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1.5em" width="1.5em" xmlns="http://www.w3.org/2000/svg"><path d="M401.4 224h-214l83-79.4c11.9-12.5 11.9-32.7 0-45.2s-31.2-12.5-43.2 0L89 233.4c-6 5.8-9 13.7-9 22.4v.4c0 8.7 3 16.6 9 22.4l138.1 134c12 12.5 31.3 12.5 43.2 0 11.9-12.5 11.9-32.7 0-45.2l-83-79.4h214c16.9 0 30.6-14.3 30.6-32 .1-18-13.6-32-30.5-32z"></path></svg>
                        </div>
                        :
                        <img className={Style.HeaderLogo} src={logo} alt="HeaderLogo" onClick={() => { handleGoToHome(); }} />
                }
                <p className={Style.HeaderTitle}>{title}</p>
                {
                    refresh &&
                    <div className={Style.HeaderIcons} onClick={refreshHandler}>
                        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="1.5em" width="1.5em" xmlns="http://www.w3.org/2000/svg"><path d="M256 388c-72.597 0-132-59.405-132-132 0-72.601 59.403-132 132-132 36.3 0 69.299 15.4 92.406 39.601L278 234h154V80l-51.698 51.702C348.406 99.798 304.406 80 256 80c-96.797 0-176 79.203-176 176s78.094 176 176 176c81.045 0 148.287-54.134 169.401-128H378.85c-18.745 49.561-67.138 84-122.85 84z"></path></svg>
                    </div>
                }
            </div>
        </div>
    );
}

export default Header;