import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { auth } from 'utils/firebase';
import Api from 'utils/endpoint';
import Style from './myWallet.module.css';
import Header from 'components/Header/Header';

const MyWallet = (props) => {
  const [coinList, setCoins] = useState([]);
  const [errorTitle, setErrorTitle] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const { code } = useParams();
  var doesTokenExist = window.localStorage.getItem("coinToken");
  useEffect(() => {
    if (code) {
      var payload = { "code": code, "kaios": true };
      const user = auth.currentUser;
      const formBody = JSON.stringify(payload, (key, value) => {
        if (value !== null) {
          return value;
        }
        return {};
      });
      user.getIdTokenResult(true).then(ss => {
        fetch(`${Api.endpoint}utils/authorizeCoinBaseToken/`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${ss.token}`,
          },
          method: "POST",
          body: formBody,
        }).then((data) => {
          data.json().then(res => {
            if (res && res.success) {
              window.localStorage.setItem("coinToken", res.data.access_token);
              fetchCoins(res.data.access_token);
            } else {
              setErrorTitle('Authoriztion Failed');
              setErrorMessage('Something went worng. Please try again.');
            }
          });
        }).catch((error) => {
          const e = {
            message: `Error: ${error}`,
          };
          setErrorTitle('Authoriztion Failed');
          setErrorMessage('Something went worng. Please try again.');
          return e;
        });
      });
    } else if (doesTokenExist) {
      fetchCoins(doesTokenExist);
    } else {
      setErrorTitle('Wallet Not linked.');
      setErrorMessage('Please link your wallet with coinbase.');
    }
  }, []);

  const linkewallet = () => {
    props.history.push('/coinbaseWallet');
  };

  const fetchCoins = (token) => {
    fetch('https://api.coinbase.com/v2/accounts?&limit=100', {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      method: "GET",
    }).then(ress => {
      ress.json().then((mainData => {
        if (mainData.errors.length) {
          setErrorTitle('Authoriztion Failed');
          setErrorMessage('Please link wallet and try again.');
        } else {
          setCoins(mainData.data);
        }
      }));
    }).catch(err => {
      setErrorTitle('Authoriztion Failed');
      setErrorMessage('Something went worng. Please try again.');
    });
  };

  const navigationHandler = () => {
    props.history.push('/home/profile');
  };
  return (
    <div className={Style.container}>
      <Header title='My Wallet' back={true} backHandler={navigationHandler} />
      {coinList && coinList.length ? coinList.map(coindata => {
        return (
          <div className={Style.tileWrapper}>
            <div className={Style.leadingcomp}>
              <img src={`https://icons.bitbot.tools/api/${coindata.currency.code.toLowerCase()}/32x32`} style={{ width: "100%", height: '100%' }} alt='' />
            </div>
            <div className={Style.sectionWrapper}>
              <div className={Style.coinName}>{coindata.currency.name}</div>
              <div className={Style.balance}>Balance: {coindata.balance.amount}</div>
            </div>
          </div>
        );
      }) : <></>}
      {errorTitle && errorTitle.length ?
        <div className={Style.messageContainer}>
          <div className={Style.messageTitle}>{errorTitle}</div>
          <div className={Style.message}>{errorMessage}</div>
          <div className={Style.buttonContainer}>
            <span style={{ color: 'white' }} onClick={linkewallet}>okay</span>
          </div>
        </div>
        : <span />}
    </div>
  );
};

export default MyWallet;