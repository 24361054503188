import React, { useEffect } from 'react';
import Header from 'components/Header/Header';
import Style from 'pages/privacyPolicy/privacyPolicy.module.css';

function PrivacyPolicyPage({ history }) {

    const backHandler = () => {
        history.push('/settings');
    };

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        document.addEventListener('keydown', onKeyDown);
        return () => { document.removeEventListener('keydown', onKeyDown); };
    }, []);

    const onKeyDown = (e) => {
        try {
            if (!e.key) {
                return;
            }
            if (e.key === 'ArrowUp') {
                window.scrollBy(0, -50);
            } else if (e.key === 'ArrowDown') {
                window.scrollBy(0, 50);
            } else if (e.key === 'Backspace') {
                history.goBack();
            } else if (e.key === 'ArrowRight' || e.key === 'SoftRight') {
                history.push('/settings');
            }
        } catch (e) {
            console.log('error');
        }
    };

    return (
        <div className='divPrivacyPolicy'>
            <Header title='Privacy Policy' back={true} backHandler={backHandler} />
            <div className={Style.container}>
                <div className={Style.Row}>
                    <div className="wpb_text_column wpb_content_element ">
                        <div className="wpb_wrapper">
                            <p>
                                This Privacy Policy governs how SHEQONOMI (“We” / “Our” / “Us”) collects, uses, stores, maintains, secures, and discloses information collected from users (each, a "User" / “You” / “Your”) of the SHEQONOMI app ("App") and https://sheqonomi.com/ website ("Site"). This Privacy Policy is a part of and shall be read with the Terms of Service.
                            </p>
                            <h2>1 Collection, Use & Retention</h2>
                            <div style={{ paddingLeft: '5px' }}>
                                <h3>1.1 Personal Information</h3>
                                <p>
                                    We may collect information that is capable of identifying an individual or which may be connected to the individual (“Personal Information”) such as name, email address, contact number, display picture, as well as other details such as gender and date of birth from Users in a variety of ways, including, but not limited to, when Users visit Our App or Site, register on the App or Site, contact Us, and in connection with other activities, services, features or resources we make available on Our App or Site.
                                </p>
                                <h3>1.2 Non-personal information</h3>
                                <p>
                                    We may additionally collect information that cannot directly identify an individual (“Non-Personal Information”) about Users whenever they interact with Our App or Site. Such Non-Personal Information may include language preferences, geographical location, the browser name, the type of device and technical information about Users, means of connection to our App, such as the operating system and the Internet service providers utilized, and other similar information.
                                    We may use the Personal Information and Non-Personal Information that We collect from You when You register, make a purchase, sign up for Our newsletter, respond to a survey or marketing communication, contact Us, surf the App or Site, or use certain other App or Site features, etc. in the following ways:
                                </p>
                                <ol>
                                    <li>
                                        To enable You to use Our App and Site and to enforce Our Terms of Service.
                                    </li>
                                    <li>
                                        To personalize and customize Your experience and to allow Us to deliver the type of content and product offerings in which You are most interested.
                                    </li>
                                    <li>
                                        To improve the App and Site's troubleshooting, functioning, and security measures.
                                    </li>
                                    <li>
                                        To communicate with You and better service You in responding to Your customer service requests.
                                    </li>
                                    <li>
                                        To ask for ratings and reviews of services or products.
                                    </li>
                                    <li>
                                        To comply with applicable laws, and any legal or regulatory obligations.
                                    </li>
                                    <li>
                                        To send periodic emails: We may use the email address to send User information and updates of the User’s order. It may also be used to respond to the User’s inquiries, questions, and/or other requests.
                                    </li>
                                </ol>
                            </div>
                            <h2>2 Security</h2>
                            <p>
                                We take all reasonable precautions to protect Your Personal Information and Non-Personal Information, both online and offline, using industry-standard technical and administrative security measures to reduce the risk of loss, misuse, unauthorized access, disclosure, or alteration. We store all Personal Information of Users on the cloud infrastructure offered by Amazon Web Services and Google Cloud Platform.
                                Amazon Web Services: https://aws.amazon.com/privacy/?nc1=f_pr
                                We do not use vulnerability scanning and/or scanning to PCI standards, and We use regular Malware Scanning.
                                We only provide articles and information. We never ask for credit card numbers.
                            </p>
                            <h2>3 Sharing & Disclosure</h2>
                            <p>
                                We do not sell, trade, rent, or otherwise transfer to third parties, Your Personal Information.
                                We do engage certain third-party service providers (“Service Providers”) as stated below to provide advertising, infrastructure and IT services, process payment transactions, etc. and such Service Providers may have access to Your Personal Information or Non-Personal Information, in the course of providing such services. These trusted Service Providers are bound by this Privacy Policy and are not authorized to use or disclose your Personal Information or Non-Personal Information except in connection with providing their services.
                                Third-Party Service Provider Privacy Links
                                <p style={{ paddingLeft: '5px' }}>Facebook: <a href='https://m.facebook.com/privacy/explanation' target='__blank'>https://m.facebook.com/privacy/explanation</a></p>
                                <p style={{ paddingLeft: '5px' }}>Google: <a href='https://policies.google.com/privacy' target='__blank'>https://policies.google.com/privacy</a></p>
                            </p>
                            <p>
                                We may share or disclose Your details to other companies in our group or transfer our databases containing your Personal Information or Non-Personal Information if we sell our business or part of it. Other than as set out in this Privacy Policy, we shall not share or disclose your Personal Information to third parties without obtaining your prior consent unless it is necessary for the purposes set out in this Privacy Policy or unless we are required to do so by law.
                            </p>
                            <p>
                                Additionally, We may disclose certain Personal or Non-Personal Information about a User as and when required for any litigation, security, law enforcement, and/or enforcement of Our Terms of Service. We also reserve the right to share any information including personal information relating to the Users with another party in case of a merger, acquisition, amalgamation, or sale of all or part of Our assets to a third party.
                                The App and Site may contain links to third-party websites that are not under our control. These websites may have their policies regarding privacy. We have no control over or responsibility for such linked websites. Your access to such linked websites shall be at your own risk. These websites are not subject to this Privacy Policy. You should check the privacy policies, if any, of such third-party websites to see how the operators of those third-party websites will utilize your Personal Information or Non-Personal Information.
                            </p>
                            <h2>4 Consent</h2>
                            <p>
                                We will collect Personal Information from Users only if they voluntarily submit such Personal Information to Us. Users can always refuse to supply Personal Information or withdraw any consent previously provided, except that it may prevent them from engaging in certain App or Site-related activities.
                            </p>
                            <p>
                                Users may modify or delete their profile and/or Personal Information provided to Us. In case a User opts to delete their profile, any content published by such User on the App and Site will also be deleted. However, You agree and accept that We may retain Your Personal Information for 180 days after such deletion to comply with applicable laws. We may continue to store and use Your Personal Information in an anonymized and aggregated manner for as long as is necessary for Us to provide Our services effectively.
                            </p>
                            <p>
                                You further expressly consent to Our continued use, storage, collection, and disclosure of Your Personal Information in the event of any change in the laws applicable to data protection in India, to the fullest extent permitted under such applicable laws.
                            </p>
                            <p>
                                You may, at any time, revoke or rescind the consent provided herein. You may request for deletion of Your account and the associated Personal Information by writing to Us at tech@sheqonomi.com. Upon any such revocation or recession of the consent or deletion of Your account, You hereby agree that You shall not be entitled to use any of the services made available on the App and Site.
                            </p>
                            <h2>5 Changes to this Privacy Policy</h2>
                            <p>
                                SHEQONOMI has the discretion to update this Privacy Policy at any time. When We do, We will post a notification on the main page of our Site. Additionally, We encourage Users to frequently check this page for any changes to stay informed about how We are helping to protect the Personal Information We collect. You acknowledge and agree that it is Your responsibility to review this Privacy Policy periodically and become aware of modifications.
                            </p>
                            <h2>6 Your acceptance of this Privacy Policy</h2>
                            <p>
                                By using the SHEQONOMI App and/or Site, You signify Your acceptance of this Privacy Policy. If You do not agree to this Privacy Policy, please do not use Our App and/or Site. Your continued use of the App and/or Site following the posting of changes to this Privacy Policy will be deemed Your acceptance of those changes. We reserve the right to terminate Your access and/or usage rights to the Site and App for any non-compliance with this Privacy Policy.
                            </p>
                            <h2>7 Contacting Us</h2>
                            <p>
                                If You have any questions about this Privacy Policy, the practices of the App or Site, or Your dealings with this App or site, please email us at SHEQONOMI -
                                tech@sheqonomi.com.
                            </p>
                            <h2>26 Grievance Redressal</h2>
                            <p>If you have any concerns or complaints regarding our services, please reach out to us promptly:</p>
                            <ul>
                                <li>Contact Person: Anu Bhardwaj</li>
                                <li>Contact: anu@womeninvesting.in</li>
                                <li>Details Needed: Share your name, contact information, and a clear description of the issue.</li>
                                <li>Resolution: We'll investigate and aim to resolve the issue swiftly.</li>
                                <li>Feedback: We value your input to enhance our services.</li>
                                <li>Escalation: If unsatisfied with the resolution, options for escalation will be provided.</li>
                            </ul>
                            <p>
                                <b>
                                    Please note the following course of action to be taken by SHEQONOMI under your grievance:
                                </b>
                            </p>
                            <ol>
                                <li>
                                    SHEQONOMI shall acknowledge the receipt of your grievance within 24 hours of your email.
                                </li>
                                <li>
                                    A unique ticket number shall be issued to you to track the status of the grievance.
                                </li>
                                <li>
                                    Based on SHEQONOMI’s review of the nature of the Content and the grievance, SHEQONOMI shall make a decision, at its sole discretion, to take down the Content if it violates these Terms of Service and/or the Community Guidelines.
                                </li>
                                <li>
                                    Such decision of SHEQONOMI regarding the Content shall be conveyed to you within 15 days of the receipt of your email.
                                </li>
                            </ol>
                            <p style={{ paddingTop: '5px' }}>
                                This document was last updated on June 3, 2024.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PrivacyPolicyPage;