import Style from 'components/Button/buttonStyles.module.css';

function Button({ buttonText, buttonOnClick, isFocus, disabled }) {
    return (
        <button
            className={Style.Button}
            onClick={buttonOnClick}
            style={{ backgroundColor: isFocus && !disabled ? '#451e53' : 'gray' }}
            disabled={disabled}
        >
            {buttonText}
        </button>
    );
}

export default Button;