import React  from 'react';
import Style from './Cards.module.css';
import formatAMPM from 'utils/timeInAmPM'
import like from 'assets/like.png';

const Cards = ({data})=>{
    return (
        <div className={Style.container}>
            <div className={Style.leadComp}>
                <img src={like} style={{width:'90%',height:'90%'}} alt=''/>
            </div>
            <div>
                <div className={Style.bodyContainer}>
                    <div className={Style.bodyTitle}>{data.network}</div>
                    <div className={Style.bodyTitle}>
                        {new Date(data.time).toLocaleDateString()} {formatAMPM(new Date(data.time))}
                    </div>
                </div>
                <div className={Style.tokenText}>{data.token}</div>
            </div>
            
        </div>
    )
}

export default Cards;