import { useEffect, useState } from 'react';

export const useNavigation = ({
  onUpKey,
  onDownKey,
  onLeftKey,
  onRightKey,
  onEnterKey,
  onBackspaceKey,
  onShiftKey,
  showExitConfirm,
}) => {

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown);
    setNavigation(0);
    return () => document.removeEventListener('keydown', onKeyDown);
  }, []);

  const [current, setCurrent] = useState({
    navigationType: 'nav',
    allCategoriesLength: 0, // For down navigation
    cardLength: 0, // For right and left navigation
    showObjective: false,
    showRepo: false,
    showHelpComponent: false,
    videoPause: false,
    landscape: false,
    hls: null,
    show: false,
    activeIndex: 0,
    repoIndex: 0,
    categoryName: '',
  });

  const setNavigation = () => current;

  const onKeyDown = async (evt) => {
    console.log(`In Use Navigation, Key Pressed : ${evt.key}`);
    if (
      evt.key !== 'ArrowRight' &&
      evt.key !== 'ArrowLeft' &&
      evt.key !== 'ArrowDown' &&
      evt.key !== 'ArrowUp' &&
      evt.key !== 'Enter' &&
      evt.key !== 'Backspace' &&
      evt.key !== 'Shift' &&
      evt.key !== 'SoftLeft' &&
      evt.key !== 'SoftRight' &&
      evt.key !== '1' &&
      evt.key !== '2' &&
      evt.key !== '3'
    ) {
      return;
    }

    switch (evt.key) {
      case 'ArrowRight':
        onRightKey(current.allCategoriesLength);
        break;
      case '3':
        break;
      case 'ArrowLeft':
        onLeftKey(current.allCategoriesLength);
        break;
      case '1':
        break;
      case 'ArrowDown':
        current.navigationType = 'card';
        setCurrent(current);
        onDownKey(current.cardLength);
        break;
      case 'ArrowUp':
        onUpKey();
        break;
      case 'Enter':
        onEnterKey();
        break;
      case 'Backspace':
        evt.preventDefault();
        onBackspaceKey(evt.key);
        break;
      case 'SoftLeft':
        onBackspaceKey(evt.key);
        break;
      case 'Shift':
        onShiftKey(current);
        break;
      case 'SoftRight':
        setCurrent(current);
        onShiftKey(current);
        break;
      default:
        break;
    }
  };

  return [current, setNavigation];
};
