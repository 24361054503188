import React, { useState, useEffect } from 'react';
import MenuButton from 'components/MenuButton/MenuButton';
import { colors } from 'utils/colors';
import Header from 'components/Header/Header';


const historyStyle = {
    width: '100%',
    display: 'flex',
    alignItems: "center",
    flexDirection: 'column',
};

function HistoryPage(props) {
    const [activeTab, setActiveTab] = useState(0);

    const backHandler = () => {
        props.history.push('/home/profile');
    };

    const rewardHistoryHandler = () => {
        props.history.push('/rewardHistory');
    };

    const shopTransactionHistoryHandler = () => {
        props.history.push('/shopHistory');
    };

    const airDropsHistoryHandler = () => {
        props.history.push('/airDropHistory');
    };


    useEffect(() => {
        document.addEventListener('keydown', onKeyDown);
        return () => { document.removeEventListener('keydown', onKeyDown); };
    }, [activeTab]);



    const onKeyDown = (e) => {
        if (e.key === 'ArrowRight') {
            backHandler();
        } else if (e.key && e.key === 'ArrowDown') {
            if (activeTab < 1) {
                setActiveTab(freshState => { return freshState + 1; });
            }
        } else if (e.key && e.key === 'ArrowUp') {
            if (activeTab > 0) {
                setActiveTab(freshState => { return freshState - 1; });
            }
        } else if (e.key && e.key === 'Enter') {
            if (activeTab === 0) {
                rewardHistoryHandler();
            } else if (activeTab === 1) {
                shopTransactionHistoryHandler();
            }
        } else if (e.key === 'Backspace') {
            backHandler();
        }
    };

    return (
        <>
            <Header title='History' back={true} backHandler={backHandler} />
            <div style={historyStyle}>
                <MenuButton buttonText='Rewards' iconBgColor={colors.ICON_BACKGROUND_PURPLE} iconColor={colors.ICON_PURPLE} onClick={rewardHistoryHandler} isFocus={activeTab === 0}>
                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M512.5 190.4l-94.4 191.3-211.2 30.7 152.8 149-36.1 210.3 188.9-99.3 188.9 99.2-36.1-210.3 152.8-148.9-211.2-30.7z"></path><path d="M908.6 352.8l-253.9-36.9L541.2 85.8c-3.1-6.3-8.2-11.4-14.5-14.5-15.8-7.8-35-1.3-42.9 14.5L370.3 315.9l-253.9 36.9c-7 1-13.4 4.3-18.3 9.3a32.05 32.05 0 0 0 .6 45.3l183.7 179.1L239 839.4a31.95 31.95 0 0 0 46.4 33.7l227.1-119.4 227.1 119.4c6.2 3.3 13.4 4.4 20.3 3.2 17.4-3 29.1-19.5 26.1-36.9l-43.4-252.9 183.7-179.1c5-4.9 8.3-11.3 9.3-18.3 2.7-17.5-9.5-33.7-27-36.3zM665.3 561.3l36.1 210.3-188.9-99.2-188.9 99.3 36.1-210.3-152.8-149 211.2-30.7 94.4-191.3 94.4 191.3 211.2 30.7-152.8 148.9z"></path></svg>
                </MenuButton>

                {/* <MenuButton buttonText='Shop Transactions' iconBgColor={colors.ICON_BACKGROUND_PURPLE} iconColor={colors.ICON_PURPLE} onClick={shopTransactionHistoryHandler} isFocus={activeTab===1}>
                <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1em" width="15em" xmlns="http://www.w3.org/2000/svg"><path d="M17.21 9l-4.38-6.56c-.19-.28-.51-.42-.83-.42-.32 0-.64.14-.83.43L6.79 9H2c-.55 0-1 .45-1 1 0 .09.01.18.04.27l2.54 9.27c.23.84 1 1.46 1.92 1.46h13c.92 0 1.69-.62 1.93-1.46l2.54-9.27L23 10c0-.55-.45-1-1-1h-4.79zM9 9l3-4.4L15 9H9zm3 8c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z"></path></svg>
                </MenuButton> */}

                {/* <MenuButton buttonText='Air Drops' iconBgColor={colors.ICON_BACKGROUND_PURPLE} iconColor={colors.ICON_PURPLE} onClick={airDropsHistoryHandler} isFocus={activeTab===2} >
                <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="1.5em" width="1.5em" xmlns="http://www.w3.org/2000/svg"><path d="M12 22c1.1 0 2-.9 2-2h-4c0 1.1.89 2 2 2zm6-6v-5c0-3.07-1.64-5.64-4.5-6.32V4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.63 5.36 6 7.92 6 11v5l-2 2v1h16v-1l-2-2z"></path></svg>
                </MenuButton> */}
            </div>
        </>
    );
}

export default HistoryPage;