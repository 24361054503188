import React, { Component } from 'react';
import ReactAudioPlayer from 'react-audio-player';
import ProgressBar from '@ramonak/react-progress-bar';
import { get, post } from '../../api/api';
import PageSpinner from 'components/Spinner/PageSpinner';
import { logEventPodcastPlayed } from 'utils/analytics';
import back from 'assets/back.png';
import pauseButton from 'assets/pause-button.png';
import forward from 'assets/forward.png';
import backward from 'assets/backward.png';
import playButton from 'assets/play.png';
import volumeUp from 'assets/volume-up.png';
import volumeDown from 'assets/volume-down.png';
import like from 'assets/heart-color.png';
import unlike from 'assets/heart-black.png';
import Style from './episodePage.module.css';
import defaultImage from 'assets/logo_short.png';
import './style.scss';

class EpisodePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: null,
            podcast: null,
            episode: null,
            likes: 0,
            playStatus: true,
            likeStatus: false,
            activeTab: 0,
            currentPlayTime: 0,
            mediaLength: 0,
            loadingText: '',
            loadingAnimation: null
        };
        this.audioPlayer = React.createRef();
        this.likeButton = React.createRef();
    }

    async componentDidMount() {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        if (this.props && this.props.location && this.props.location.state) {
            const { podcast, episode } = this.props.location.state;
            if (podcast && episode) {
                this.setState({
                    podcast: podcast,
                    episode: episode,
                    likes: episode.likes || 0,
                    likeStatus: episode.likeStatus
                }, async () => {
                    await this.fetchUser();
                });
            } else {
                await this.fetchUser();
                await this.getEpisodeAndPodcast();
            }
        } else {
            await this.fetchUser();
            await this.getEpisodeAndPodcast();
        }
        document.addEventListener('keydown', this.onKeyDown);
    }

    getEpisodeAndPodcast = async () => {
        const { match } = this.props;
        const { id, episode: episodeId } = match.params;
        try {
            const response = await get(`podcasts/getEpisode/${id}/${episodeId}`);
            const { podcast, episode } = response;
            this.setState({ podcast: podcast, episode: episode });
            logEventPodcastPlayed(this.state.user, {
                Category: this.state.podcast.categories || '',
                PodcastName: this.state.podcast.title || '',
                Author: this.state.podcast.author || '',
                MediaURL: this.state.episode.url
            });
        } catch (error) {
            console.error('Error fetching user data', error);
        }
    };

    componentWillUnmount() {
        document.removeEventListener('keydown', this.onKeyDown);
    }

    fetchUser = async () => {
        try {
            const response = await get('user/me');
            const { user } = response;
            this.setState({ user });
            logEventPodcastPlayed(user, {
                Category: this.state.podcast.categories || '',
                PodcastName: this.state.podcast.title || '',
                Author: this.state.podcast.author || '',
                MediaURL: this.state.episode.url
            });
        } catch (error) {
            console.error('Error fetching user data', error);
        }
    };

    volumeUpDown = (action) => {
        if (navigator && navigator.volumeManager) {
            if (action === 'up') {
                navigator.volumeManager.requestUp();
            } else {
                navigator.volumeManager.requestDown();
            }
        }
    };

    playPause = () => {
        if (this.state.playStatus) {
            this.audioPlayer.current.audioEl.current.pause();
            this.setState({ playStatus: false });
        } else {
            this.audioPlayer.current.audioEl.current.play();
            this.setState({ playStatus: true });
        }
    };

    updateLoadingText = () => {
        this.setState((prevState) => {
            const newText = prevState.loadingText === '...' ? '.' : prevState.loadingText + '.';
            return { loadingText: newText };
        });
    };

    likeHandler = async () => {
        try {
            this.loadingAnimation = setInterval(this.updateLoadingText, 500);
            const body = { _id: this.state.episode._id };
            await post('podcasts/likeUnlikeEpisode', body);
            if (this.loadingAnimation) {
                clearInterval(this.loadingAnimation);
            }
            const likeStatus = !this.state.likeStatus;
            let likes = this.state.likes;
            if (likeStatus) {
                likes = likes + 1;
            } else {
                if (likes > 0) {
                    likes = likes - 1;
                }
            }
            this.setState({
                likeStatus: likeStatus,
                likes: likes,
                loadingText: '',
            });
            if (this.loadingAnimation) {
                clearInterval(this.loadingAnimation);
            }
        } catch (e) {
            this.setState({ loadingText: '' });
            if (this.loadingAnimation) {
                clearInterval(this.loadingAnimation);
            }
        }
    };

    onLoadedMetadata = async (meta) => {
        await this.setState({ mediaLength: Math.floor(meta.target.duration) });
    };

    onListen = () => {
        this.setState(prevState => ({ currentPlayTime: prevState.currentPlayTime + 1 }));
    };

    jumpForward = () => {
        const { currentPlayTime, mediaLength } = this.state;
        if (currentPlayTime >= mediaLength) {
            return;
        }
        const secondsToGoForward = 5;
        this.audioPlayer.current.audioEl.current.currentTime += secondsToGoForward;
        this.setState(prevState => ({ currentPlayTime: prevState.currentPlayTime + secondsToGoForward }));
    };

    jumpBackward = () => {
        const { currentPlayTime } = this.state;
        if (currentPlayTime <= 0) {
            return;
        }
        const secondsToGoBack = 5;
        this.audioPlayer.current.audioEl.current.currentTime -= secondsToGoBack;
        this.setState(prevState => ({ currentPlayTime: prevState.currentPlayTime - secondsToGoBack }));
    };

    onKeyDown = (e) => {
        if (!e.key) {
            return;
        }
        if (e.key === '5') {
            this.likeHandler();
        } else if (e.key === 'Enter') {
            this.playPause();
        } if (e.key === 'SoftRight' || e.key === 'Backspace') {
            this.props.history.goBack();
        } else if (e.key === 'ArrowDown' || e.key === 'VolumeDown') {
            this.volumeUpDown('down');
        } else if (e.key === 'ArrowUp' || e.key === 'VolumeUp') {
            this.volumeUpDown('up');
        } else if (e.key === 'ArrowLeft' || e.key === '1') {
            this.jumpBackward();
        } else if (e.key === 'ArrowRight' || e.key === '3') {
            this.jumpForward();
        }
    };

    formatTime = (time) => {
        if (time && !isNaN(time)) {
            const minutes = Math.floor(time / 60);
            const formatMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
            const seconds = Math.floor(time % 60);
            const formatSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
            return `${formatMinutes}:${formatSeconds}`;
        }
        return '00:00';
    };

    render() {
        const { playStatus, episode, likeStatus, likes, currentPlayTime, mediaLength, activeTab, loadingText } = this.state;
        const innerHeight = window.innerHeight;
        const innerWidth = window.innerWidth;

        return (
            <div className={Style.container} style={{ height: innerHeight, width: innerWidth }}>
                {!episode ? (
                    <PageSpinner />
                ) : (

                    <>
                        <div className={Style.headerComponent}>
                            <div className={Style.backButton}>
                                <img src={back} alt='back' style={{ marginLeft: '10px', height: '20px', width: '20px' }} onClick={this.props.history.goBack} />
                                <span className={Style.episodeTitle}> {episode.title}</span>
                            </div>
                            <div className={Style.podcastImage}>
                                <img src={episode.thumbnailURL80x80 || defaultImage} alt='podcastImage' className={Style.podcastBanner} />
                            </div>
                            <div className={Style.likeContainer} onClick={this.likeHandler} style={{ border: activeTab === 1 ? '1px solid #0a0a0a' : '1px solid #e8e3e3' }}>
                                <button ref={this.likeButton} style={{ width: '100%' }}>
                                    <span>
                                        <img src={likeStatus ? like : unlike} alt='' className={Style.likeImage} />
                                        <span className={Style.likeButton}>{likes || ''} Like {loadingText}</span>
                                    </span>
                                </button>
                            </div>
                            <div className={Style.likeContainer} onClick={this.likeHandler} style={{ border: activeTab === 1 ? '1px solid #0a0a0a' : '1px solid #e8e3e3' }}>
                                <span>Press 5</span>
                            </div>
                        </div>
                        <div className={Style.mediaContainer}>
                            <div className={Style.headerComponent}>
                                <div className={Style.controlContainer}>
                                    {
                                        episode.url &&
                                        <div className={Style.playerWrapper} onClick={(e) => { this.volumeUpDown('down'); }} style={{ cursor: 'pointer', border: '1px solid white' }}>
                                            <img src={volumeDown} className={Style.volumeButton} alt='volume down' />
                                        </div>
                                    }
                                    {
                                        episode.url &&
                                        <>
                                            <div className={Style.playerWrapper} onClick={this.jumpBackward} style={{ cursor: 'pointer', border: '1px solid white' }}>
                                                <img src={backward} className={Style.playButton} alt='backward' />
                                            </div>
                                            <div className={Style.playerWrapper} onClick={this.playPause} style={{ cursor: 'pointer', border: '1px solid white' }}>
                                                <img src={playStatus ? pauseButton : playButton} className={Style.playButton} alt='player' />
                                            </div>
                                            <div className={Style.playerWrapper} onClick={this.jumpForward} style={{ cursor: 'pointer', border: '1px solid white' }}>
                                                <img src={forward} className={Style.playButton} alt='forward' />
                                            </div>
                                        </>
                                    }
                                    {
                                        episode.url &&
                                        <div className={Style.playerWrapper} onClick={(e) => { this.volumeUpDown('up'); }} style={{ cursor: 'pointer', border: '1px solid white' }}>
                                            <img src={volumeUp} className={Style.volumeButton} alt='volume up' />
                                        </div>
                                    }
                                </div>
                                {
                                    episode.url &&
                                    <div className={Style.progressBarContainer}>
                                        <span className={Style.duration}>{this.formatTime(currentPlayTime)}</span>
                                        <ProgressBar
                                            className='progressBarWrapper'
                                            customLabel=' '
                                            completed={Math.round((currentPlayTime * 100) / mediaLength)}
                                            bgColor='#442253'
                                            height='5px'
                                            width='100%'
                                        />
                                        <span className={Style.duration}>{this.formatTime(mediaLength)}</span>
                                    </div>
                                }
                                {
                                    episode.url &&
                                    <ReactAudioPlayer
                                        ref={this.audioPlayer}
                                        src={episode.url}
                                        autoPlay={true}
                                        listenInterval={1000}
                                        onListen={() => this.onListen()}
                                        onLoadedMetadata={async (e) => await this.onLoadedMetadata(e)}
                                    />
                                }
                            </div>
                        </div>
                    </>
                )}
            </div>
        );
    }
}

export default EpisodePage;