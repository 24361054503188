import React, { useState, useEffect } from 'react';
import { auth, firestore } from 'utils/firebase';
import { getDoc, doc } from 'firebase/firestore';
import Styles from './shopHistoryStyles.module.css';
import Header from 'components/Header/Header';
import PageSpinner from 'components/Spinner/PageSpinner';
import Card from 'components/Cards/Card';

function ShopHistoryPage(props) {
    const [shopHistory, setShopHistory] = useState([]);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const user = auth.currentUser;
        if (user != null) {
            const userDataRef = doc(firestore, 'users', user.uid);
            getDoc(userDataRef).then((doc) => {
                if (doc.exists() && doc.data().products) {
                    setShopHistory(doc.data().products);
                    setLoading(false);
                } else {
                    setLoading(false);
                }
            });

        } else {
            setLoading(false);
        }
    }, []);

    const backHandler = () => {
        props.history.push('/history');
    };

    return (
        <>
            <Header title='Shop History' back={true} backHandler={backHandler} />
            {loading && <PageSpinner />}
            {shopHistory.length > 0 && shopHistory.map((data, i) => (
                <Card
                    leftValue={data.id}
                    rightValue={data.price}
                    lowerValue={data.timestamp}
                    rightText={'Points'}
                    icon={'like'}
                    key={i} />
            ))}
            {!loading && shopHistory.length == 0 &&
                <div className={Styles.container}>
                    No Transaction History
                </div>
            }
        </>
    );
}

export default ShopHistoryPage;