import React, { Component } from 'react';
import { get } from '../../api/api';
import PageSpinner from 'components/Spinner/PageSpinner';
import PodcastList from 'components/PodcastList/PodcastList';
import Header from 'components/Header/Header';
import Navigation from 'navigation/navigation';
import Style from './Podcast.module.css';
import { logEventPodcastsTab } from 'utils/analytics';

class Podcast extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      podcastFeed: [],
      activeTab: 0,
      loading: true,
      nextLoading: false,
      previousLoading: false,
      nextSkip: 0,
      nextPage: true,
      loadingText: '',
      loadingAnimation: null,
      successMessage: '',
      errMessage: ''
    };
  }

  async componentDidMount() {
    this.loadOfflineData();
    await this.getUser();
    document.addEventListener('keydown', this.onKeyDown);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDown);
  }

  getUser = async () => {
    try {
      const response = await get('/user/me');
      const { user } = response;
      if (user) {
        this.setState({ user: user });
        await this.getData();
      }
    } catch (error) {
      this.setState({ errMessage: error || 'Something Went Wrong' });
    }
  };

  getData = async () => {
    try {
      window.scrollTo({ bottom: 0, behavior: 'smooth' });
      const response = await get('/podcasts/getTrending', { skip: this.state.nextSkip });
      const { podcasts, nextSkip, nextPage } = response;
      let allPodcasts = [...this.state.podcastFeed, ...podcasts].filter((podcast, index, self) =>
        index === self.findIndex((checkPodcast) => checkPodcast.rssURL === podcast.rssURL)
      );
      if (this.state.nextPage === 0 && allPodcasts.length > 0) {
        allPodcasts = allPodcasts.slice(0, Math.min(50, allPodcasts.length));
        localStorage.setItem('podcastFeedData', JSON.stringify(allPodcasts));
      }
      this.setState({
        podcastFeed: podcasts.length ? podcasts : allPodcasts,
        loading: false,
        nextSkip: nextSkip,
        nextPage: nextPage,
        nextLoading: false,
        previousLoading: false,
        loadingText: ''
      });
      if (this.loadingAnimation) {
        clearInterval(this.loadingAnimation);
      }
      logEventPodcastsTab(this.user);
    } catch (error) {
      this.loadOfflineData();
      this.setState((prevState) => ({
        loading: false,
        nextLoading: false,
        previousLoading: false,
        loadingText: '',
        errMessage: error || 'Something Went Wrong'
      }));
    }
  };

  loadOfflineData = () => {
    const podcastFeedData = localStorage.getItem('podcastFeedData');
    const podcastFeeds = podcastFeedData ? JSON.parse(podcastFeedData) : null;
    if (podcastFeeds) {
      this.setState({
        podcastFeed: podcastFeeds,
        errMessage: ''
      });
    }
  };

  updateLoadingText = () => {
    this.setState((prevState) => {
      const newText = prevState.loadingText === '...' ? '.' : prevState.loadingText + '.';
      return { loadingText: newText };
    });
  };

  loadNext = async () => {
    if (this.state.loading) {
      return;
    }
    if (!this.state.nextPage) {
      return;
    }
    if (this.state.nextLoading || this.state.previousLoading) {
      return;
    }
    this.setState({
      nextLoading: true,
    }, async () => {
      this.loadingAnimation = setInterval(this.updateLoadingText, 500);
      await this.getData();
    });
  };

  loadPrevious = async () => {
    if (this.state.loading) {
      return;
    }
    if (this.state.nextLoading || this.state.previousLoading) {
      return;
    }
    let nextSkip = this.state.nextSkip;
    if (!nextSkip || nextSkip <= 50) {
      return;
    }
    nextSkip = (nextSkip === 50) ? 0 : nextSkip - 100;
    this.setState({
      previousLoading: true,
      nextSkip: nextSkip
    }, async () => {
      this.loadingAnimation = setInterval(this.updateLoadingText, 500);
      await this.getData();
    });
  };

  scrollToCardById = (id) => {
    setTimeout(() => {
      const divElement = document.getElementById(`podcastCard-${id}`);
      if (divElement) {
        window.scroll({
          top: divElement.offsetTop,
          left: 0,
          behavior: 'smooth'
        });
      }
    });
  };

  onKeyDown = (e) => {
    if (!e.key) {
      return;
    }
    if (e.key === 'ArrowRight') {
      this.props.history.push('/home/profile');
    } else if (e.key === 'ArrowLeft') {
      this.props.history.push('/');
    } else if (e.key === 'ArrowDown') {
      this.setState((prev) => {
        if (prev.activeTab < prev.podcastFeed.length) {
          setTimeout(() => { this.scrollToCardById(prev.activeTab + 1); });
          return ({ activeTab: prev.activeTab + 1 });
        } else {
          window.scrollTo({ bottom: 0, behavior: 'smooth' });
        }
      });
    } else if (e.key === 'ArrowUp') {
      this.setState((prev) => {
        if (prev.activeTab > 0) {
          setTimeout(() => { this.scrollToCardById(prev.activeTab - 1); });
          return ({ activeTab: prev.activeTab - 1 });
        } else {
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }
      });
    } else if (e.key === 'SoftLeft' || e.key === 'Enter') {
      const data = this.state.podcastFeed[this.state.activeTab];
      this.props.history.push(`/podcastdetail/${data._id}?`);
    } else if (e.key === 'SoftRight' || e.key === 'Backspace') {
      this.props.history.goBack();
    }
  };

  render() {
    const { podcastFeed, activeTab, loading, nextPage, nextSkip, nextLoading, previousLoading, successMessage, errMessage } = this.state;
    return (
      <div>
        <Header title='Podcast' />
        {loading ? (
          <PageSpinner />
        ) : (
          <>
            {successMessage.length ? <span style={{ marginTop: 10, color: 'green', fontSize: '0.8rem' }}>{successMessage}</span> : <></>}
            {errMessage.length ? <span style={{ marginTop: 10, color: 'red', fontSize: '0.8rem' }}>{errMessage}</span> : <></>}
            <PodcastList data={podcastFeed} loading={loading} activeElement={activeTab} />
            <div style={{ height: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              {nextSkip > 50 &&
                <span className={Style.button} style={{ cursor: 'pointer', marginRight: '5px' }} onClick={this.loadPrevious}>
                  {previousLoading ? `Loading${this.state.loadingText}` : '< Previous'}
                </span>
              }
              {nextPage &&
                <span className={Style.button} style={{ cursor: 'pointer' }} onClick={this.loadNext}>
                  {nextLoading ? `Loading${this.state.loadingText}` : 'Next >'}
                </span>
              }
            </div>
          </>
        )}
        <div style={{ height: '60px' }}></div>
        <Navigation active='Podcast' />
      </div>
    );
  }
}

export default Podcast;
