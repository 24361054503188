import React  from 'react';
import Style from './Cards.module.css';
import formatAMPM from 'utils/timeInAmPM'
import like from 'assets/like.png';
import dislike from 'assets/dislike.png';
import podcast from 'assets/podcast.png';
import share from 'assets/share.png';
import unfollow from 'assets/plus.png'

const Card = ({leftValue, rightValue, rightText, lowerValue, icon})=>{
    // console.log(lowerValue);
    const iconData= {
        // 'follow': 'follow',
        'unfollow': unfollow,
        'like': like,
        'unlike': dislike,
        'share': share,
        'listen': podcast
    }
    return (
        <div className={Style.container}>
            <div className={Style.leadComp}>
                {iconData[icon] ? 
                <img src={iconData[icon]} style={{width:'100%',height:'100%'}} alt=''/>
                :
                <div className={Style.text}>-1</div>
                }
            </div>
            <div className={Style.verticalContainer}>
                <div className={Style.bodyContainer}>
                    <div className={Style.bodyTitle}>{leftValue}</div>
                    <div className={Style.bodyPoints}>
                        {rightText}: {rightValue}
                    </div>
                </div>
                <div className={Style.tokenText}>Points earned on {new Date(lowerValue*1000).toDateString()} {formatAMPM(new Date(lowerValue*1000))}</div>
            </div>
        </div>
    )
}

export default Card;