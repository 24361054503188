/* eslint-disable no-unreachable */
/* eslint-disable no-lone-blocks */
import React, { useState, useEffect } from 'react';
import { auth } from 'utils/firebase';
import Style from './linkWallet.module.css';
import Header from 'components/Header/Header';
import { useHistory } from 'react-router-dom';
import ListTile from 'components/ListTile/listTile';
import AddAddress from 'pages/addAddress/addAddress';
import Api from 'utils/endpoint';


const LinkWallet = () => {
  const navigate = useHistory();
  const [showCoins, setShowCoins] = useState(true);
  const [msg, setMsg] = useState('');
  const [cryptoSelected, setCryptoSelected] = useState('');
  const [activeTab, setActiveTab] = useState(0);
  const [address, setAddress] = useState('');
  const [error, setError] = useState('');


  const linkAddress = () => {
    const isValid = addressValidation(address);
    if (isValid) {
      setError("");
      linkwallet(address, cryptoSelected);
    } else {
      setError("Invalid Address");
    }
  };
  const addressValidation = (address) => {
    let isValid;
    let exp = '';
    switch (cryptoSelected) {
      case "bitcoin":
        {
          exp =
            new RegExp("^[13][a-km-zA-HJ-NP-Z1-9]{25,34}");
          return exp.test(address);
        }
        break;

      case "etherium":
        {
          exp = new RegExp("^0x[a-fA-F0-9]{40}");
          return exp.test(address);
        }
        break;

      case "binance":
        {
          exp = new RegExp("^0x[a-fA-F0-9]{40}");
          return exp.test(address);
        }
        break;
      case "tron":
        {
          exp = new RegExp("[13][a-km-zA-HJ-NP-Z1-9]{25,34}");
          return exp.test(address);
        }
        break;
      default:
        {
          //statements;
          isValid = false;
        }
        break;
    }
  };
  const goBack = () => {
    navigate.goBack();
  };
  const toogleUI = (crypto = '') => {
    const tabIndex = crypto ? 1 : 0;
    setShowCoins((showCoins) => !showCoins);
    setCryptoSelected(crypto);
    setMsg('');
    setActiveTab(tabIndex);
  };

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown);
    return () => { document.removeEventListener('keydown', onKeyDown); };
  }, [activeTab]);

  const onKeyDown = (e) => {
    if (e.key === 'Backspace' || e.key === 'ArrowLeft') {
      navigate.goBack();
    }
    if (e.key && e.key === 'ArrowDown') {
      if (activeTab < 3) {
        setActiveTab(freshState => {
          return freshState + 1;

        });
      }
    }
    if (e.key && e.key === 'ArrowUp') {
      if (activeTab > 0) {
        setActiveTab(freshState => {
          if (freshState > 0) {
            return freshState - 1;
          }
          return freshState;
        });
      }
    }
    if (e.key && e.key === 'Enter') {
      if (showCoins) {
        if (activeTab === 0) {
          toogleUI('bitcoin');
        }
        if (activeTab === 1) {
          toogleUI('etherium');
        }
        if (activeTab === 2) {
          toogleUI('binace');
        }
        if (activeTab === 3) {
          toogleUI('tron');
        }
      } else {
        if (activeTab === 0) {
          toogleUI();
        }
        if (activeTab === 2) {
          linkAddress();
        }
      }
    }
  };


  const coibaseHandler = () => {
    navigate.push('/coinbaseWallet');
  };



  const linkwallet = (address, currency) => {
    var payload = { "address": address, "currency": currency };
    const user = auth.currentUser;
    const formBody = JSON.stringify(payload, (key, value) => {
      if (value !== null) {
        return value;
      }
      return {};
    });
    user.getIdTokenResult(true).then(ss => {
      fetch(`${Api.endpoint}user/linkWallet/`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${ss.token}`,
        },
        method: "PUT",
        body: formBody,
      }).then(() => {
        setMsg(() => 'linked Successfully');
      }).catch((error) => {
        const e = {
          message: `Error: ${error}`,
        };
        return e;
      });
    });
  };

  const inputChangeHandler = (e) => {
    setAddress(e.target.value);
  };




  return (
    <>
      <Header title='Link Wallet' back={true} backHandler={goBack} />
      {showCoins ? <div className={Style.bodyContainer}>
        <ListTile id='bitcoin' name='Bitcoin' icon="https://icons.bitbot.tools/api/btc/32x32" toogleUI={toogleUI} isFocus={activeTab === 0} />
        <ListTile id='etherium' name='Etherium' icon="https://icons.bitbot.tools/api/eth/32x32" toogleUI={toogleUI} isFocus={activeTab === 1} />
        <ListTile id='binace' name='Binace Smart Coin' icon="https://assets.coingecko.com/coins/images/825/small/binance-coin-logo.png?1547034615" toogleUI={toogleUI} isFocus={activeTab === 2} />
        <ListTile id='tron' name='Tron' icon="https://assets.coingecko.com/coins/images/8043/small/888token_.png?1607991831" toogleUI={toogleUI} isFocus={activeTab === 3} />
        <span>Or</span>
        <ListTile id='tron' name='Connect Using Coinbase' icon="https://assets.coingecko.com/coins/images/8043/small/888token_.png?1607991831" toogleUI={coibaseHandler} />
        <div></div>
      </div> : (
        <AddAddress
          toogleUI={toogleUI}
          cryptoSelected={cryptoSelected}
          linkwallet={linkAddress}
          message={msg}
          activeTab={activeTab}
          inputHandler={inputChangeHandler}
          error={error} />
      )}
    </>
  );
};

export default LinkWallet;