import React from "react";
import style from './PageSpinner.module.css';
import spinGIF from 'assets/spinner.gif';


const PageSpinner = () => {
  return (
      // <div className={style.loadContainer}>
        <div className={style.loader}>
          {/* <p style={{fontSize:'0.8rem'}}>Loading...</p> */}
        <img src={spinGIF} style={{height:'70px',width:'90px'}} alt=''/>
        </div>
      // </div>
  );
}

export default PageSpinner;