import React  from 'react';
import Style from './listTile.module.css';


const ListTile = ({name,icon,id,toogleUI,isFocus})=>{
    return (
        <div className={Style.container} onClick={()=>{toogleUI(id)}} style={{backgroundColor:isFocus?'#c4b5c9':"white"}}>
            <div className={Style.leadComp}>
                <img src={icon} style={{width:'100%',height:'100%'}} alt=''/>
            </div>
            <div className={Style.bodyTitle}>{name}</div>
        </div>
    )
}

export default ListTile;