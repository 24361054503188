import React, { Component } from 'react';
import { get, post } from '../../api/api';
import PageSpinner from 'components/Spinner/PageSpinner';
import Style from './podcastDetail.module.css';
import back from 'assets/back.png';
import playbutton from 'assets/play-button.png';
import ReadMoreText from 'components/ReadMoreText/ReadMoreText';
import spinGIF from 'assets/spinner.gif';
import defaultImage from 'assets/logo_short.png';
import { logEventPodcastDetails } from 'utils/analytics';

class PodcastDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: null,
            podcast: null,
            episodes: [],
            following: false,
            selectedIndex: -1,
            skip: 0,
            nextSkip: 0,
            nextPage: true,
            activeIndex: -3,
            nextLoading: false,
            previousLoading: false,
            loadingText: '',
            loadingAnimation: null,
            podcastLoading: true,
            episodeLoading: false,
            successMessage: '',
            errorMessage: ''
        };
    }

    async componentDidMount() {
        await this.fetchUser();
        document.addEventListener('keydown', this.onKeyDown);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.onKeyDown);
    }

    fetchUser = async () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        try {
            const response = await get('user/me');
            const { user } = response;
            this.setState({ user }, () => {
                this.getPodcasts();
            });
        } catch (error) {
            this.setState({ errorMessage: error || 'Error fetching user details' });
        }
    };

    getPodcasts = async () => {
        try {
            const { id } = this.props.match.params;
            this.setState({ podcastLoading: true });
            const response = await get(`podcasts/${id}`);
            const { podcast } = response;
            this.setState({
                podcast,
                following: podcast.isFollow,
                podcastLoading: false,
                episodeLoading: true
            }, async () => {
                await this.getEpisodes();
            });
            logEventPodcastDetails(this.state.user, {
                Category: podcast.categories || [],
                PodcastName: podcast.title,
                Author: podcast.author,
            });
        } catch (error) {
            this.setState({
                podcastLoading: false,
                errorMessage: error || 'Error fetching podcast details'
            });
        }
    };

    getEpisodes = async () => {
        try {
            this.setState({ episodeLoading: true });
            const response = await get(`podcasts/getEpisodes/${this.state.podcast._id}?skip=${this.state.nextSkip}`);
            const { episodes, nextSkip, nextPage } = response;
            const episodesCount = episodes.length;
            const previousButtonIndexNumber = episodesCount === 50 ? 51 : episodesCount + 1;
            const nextButtonIndexNumber = previousButtonIndexNumber + 1;
            this.setState({
                episodes,
                nextSkip,
                nextPage,
                nextLoading: false,
                previousLoading: false,
                loadingText: '',
                previousButtonIndexNumber,
                nextButtonIndexNumber,
                episodeLoading: false
            });
            const selectedEpisode = episodes[this.state.activeIndex];
            if (selectedEpisode) {
                this.setState({ selectedEpisode: selectedEpisode });
            }
            if (this.loadingAnimation) {
                clearInterval(this.loadingAnimation);
            }
        } catch (error) {
            this.setState({
                episodeLoading: false,
                errorMessage: error || 'Error fetching episodes'
            });
        }
    };

    followToggle = async () => {
        try {
            this.loadingAnimation = setInterval(this.updateLoadingText, 500);
            const body = { _id: this.state.podcast._id };
            const response = await post('podcasts/followUnFollow', body);
            const { podcast } = response;
            if (this.loadingAnimation) {
                clearInterval(this.loadingAnimation);
            }
            this.setState({
                podcast: podcast,
                following: podcast.isFollow,
                loadingText: '',
            });
        } catch (error) {
            this.setState({
                loadingText: '',
                errorMessage: error || 'Error fetching episodes'
            });
            if (this.loadingAnimation) {
                clearInterval(this.loadingAnimation);
            }
        }
    };

    handlePreviousClick = () => {
        if (this.state.nextLoading || this.state.previousLoading) {
            return;
        }
        let nextSkip = this.state.nextSkip;
        if (!nextSkip || nextSkip <= 50) {
            return;
        }
        nextSkip = (nextSkip === 50) ? 0 : nextSkip - 100;
        this.setState({
            previousLoading: true,
            nextSkip: nextSkip
        }, async () => {
            this.loadingAnimation = setInterval(this.updateLoadingText, 500);
            await this.getEpisodes();
        });
    };

    handleNextClick = () => {
        if (!this.state.nextPage) {
            return;
        }
        if (this.state.nextLoading || this.state.previousLoading) {
            return;
        }
        this.setState({
            nextLoading: true,
        }, async () => {
            this.loadingAnimation = setInterval(this.updateLoadingText, 500);
            await this.getEpisodes();
        });
    };

    handleIndex = (value) => {
        this.setState({ selectedIndex: value });
    };

    updateLoadingText = () => {
        this.setState((prevState) => {
            const newText = prevState.loadingText === '...' ? '.' : prevState.loadingText + '.';
            return { loadingText: newText };
        });
    };

    playEpisode = (_id) => {
        const episode = this.state.episodes.find(e => e._id === _id);
        if (!episode) {
            return;
        }
        this.setState({ selectedEpisode: episode }, () => {
            this.props.history.push({
                pathname: `/episodePage/${this.state.podcast._id}/${_id}`,
                state: { podcast: this.state.podcast, episode: this.state.selectedEpisode }
            });
        });
    };

    onKeyDown = (e) => {
        if (!e.key) {
            return;
        }
        if (e.key === 'ArrowRight') {
            this.handleNextClick();
        } else if (e.key === 'ArrowLeft') {
            this.handlePreviousClick();
        } else if (e.key === 'ArrowDown') {
            if (this.state.activeIndex > 53) {
                return;
            }
            this.setState({ activeIndex: this.state.activeIndex + 1 });
        } else if (e.key === 'ArrowUp') {
            if (this.state.activeIndex < -3) {
                return;
            }
            this.setState({ activeIndex: this.state.activeIndex - 1 });
        } else if (e.key === 'SoftLeft' || e.key === 'Enter') {
            if (this.state.activeIndex === -2) {
                this.followToggle();
            } else if (this.state.activeIndex === -1) {
                this.setState({ selectedIndex: (-1 * this.state.selectedIndex) - 1 });
            } else {
                const selectedEpisode = this.state.episodes[this.state.activeIndex];
                if (selectedEpisode) {
                    this.setState({ selectedEpisode: selectedEpisode }, () => {
                        this.playEpisode(this.state.selectedEpisode._id);
                    });
                }
            }
        } else if (e.key === 'SoftRight' || e.key === 'Backspace') {
            this.props.history.goBack();
        }
        if (this.state.activeIndex !== -1) {
            this.setState({ selectedIndex: -1 });
        }
    };

    goBack = () => { this.props.history.goBack(); };

    render() {
        const {
            podcast,
            episodes,
            following,
            loadingText,
            selectedIndex,
            nextSkip,
            nextPage,
            activeIndex,
            podcastLoading,
            episodeLoading,
            nextLoading,
            previousLoading,
            previousButtonIndexNumber,
            nextButtonIndexNumber,
            successMessage,
            errorMessage,
        } = this.state;

        return (
            <div className={Style.container}>
                <div className={Style.headerComponent}>
                    {successMessage.length ? <span style={{ marginTop: 10, color: 'green', fontSize: '0.8rem' }}>{successMessage}</span> : <></>}
                    {errorMessage.length ? <span style={{ marginTop: 10, color: 'red', fontSize: '0.8rem' }}>{errorMessage}</span> : <></>}
                    <div className={Style.backButton}>
                        <img src={back} alt='back' style={{ height: '20px', width: '20px' }} onClick={this.goBack} />
                    </div>
                    {
                        podcastLoading ? (
                            <PageSpinner />
                        ) : (
                            <>
                                <div className={Style.podcastImage}>
                                    {podcast ? (
                                        <img src={podcast.thumbnailURL80x80 || defaultImage} alt='podcastImage' className={Style.podcastBanner} />
                                    ) : (
                                        <img src={spinGIF} alt='podcastImage' className={Style.imgSpinner} />
                                    )}
                                </div>
                                {podcast && (
                                    <div className={Style.podcastToolbar}>
                                        <div className={Style.metaDataBox}>
                                            <div className={Style.detailContainer}>
                                                <span className={Style.lightText}>{podcast.followers ? podcast.followers : '0'}</span>
                                                <span className={Style.lightText}>Followers</span>
                                            </div>
                                            <div className={Style.detailContainer}>
                                                <span className={Style.lightText}>
                                                    {podcast.episodesCount || '0'}
                                                </span>
                                                <span className={Style.lightText}>Episodes</span>
                                            </div>
                                        </div>
                                        <div
                                            id="followButton"
                                            className={Style.followBox}
                                            onClick={this.followToggle}
                                            style={{ backgroundColor: activeIndex === -2 ? '#c4b5c9' : 'white', cursor: 'pointer' }}
                                        >
                                            {following ? (
                                                <span
                                                    className={Style.lightText}
                                                    style={{ cursor: 'pointer', marginLeft: '3px', color: '#451e53', fontWeight: 'bold' }}
                                                >
                                                    Unfollow{loadingText}
                                                </span>
                                            ) : (
                                                <span className={Style.lightText} style={{ cursor: 'pointer', marginLeft: '3px' }}>
                                                    Follow{loadingText}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </>
                        )
                    }
                </div>
                {!podcastLoading && podcast && (
                    <div id="divReadMoreLess" className={Style.description}>
                        <span className={Style.boldText}>{podcast.title ? podcast.title : ''}</span>
                        <span className={Style.lightText}>
                            <ReadMoreText
                                content={podcast.description ? podcast.description : ''}
                                length={25}
                                isFocus={activeIndex === -1}
                                index={0}
                                selectedIndex={selectedIndex}
                                handleIndex={this.handleIndex}
                                readLess={true}
                            />
                        </span>
                    </div>
                )}
                <div className={Style.episodeHeaderComponent}>
                    {
                        episodeLoading ? (
                            <PageSpinner />
                        ) : (
                            <>
                                {
                                    episodes.map((episode, index) => (
                                        <div
                                            className={`${Style.episodeList} card`}
                                            id={`card-${index}`}
                                            key={index}
                                            tabIndex='0'
                                            onClick={() => this.playEpisode(episode._id)}
                                        >
                                            <div
                                                className={Style.episodeTile}
                                                style={{
                                                    backgroundColor: activeIndex === index ? '#c4b5c9' : 'white'
                                                }}
                                            >
                                                <div className={Style.episodeTitle}>
                                                    <span className={Style.lightText} style={{ marginLeft: '3px' }}>
                                                        {episode.title}{' '}
                                                    </span>
                                                </div>
                                                <div className={Style.playIcon}>
                                                    <img
                                                        src={playbutton}
                                                        style={{ height: '100%', width: '100%' }}
                                                        alt='forward'
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </>
                        )
                    }
                    {
                        episodes.length > 0 &&
                        <div style={{ height: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            {nextSkip > 50 && (
                                <span
                                    className={Style.button}
                                    style={{ cursor: 'pointer', marginRight: '5px', border: (activeIndex === previousButtonIndexNumber) ? 'solid 2px' : 'none' }}
                                    onClick={this.handlePreviousClick}
                                >
                                    {previousLoading ? `Loading${loadingText}` : '< Previous'}
                                </span>
                            )}
                            {nextPage && (
                                <span className={Style.button} style={{ cursor: 'pointer', border: (activeIndex === nextButtonIndexNumber) ? 'solid 2px' : 'none' }} onClick={this.handleNextClick} >
                                    {nextLoading ? `Loading${loadingText}` : 'Next >'}
                                </span>
                            )}
                        </div>
                    }
                </div>
            </div>
        );
    }
}

export default PodcastDetail;