import React from 'react';
import Style from './coinbaseWallet.module.css';
import PageSpinner from 'components/Spinner/PageSpinner';
class CoinbaseWallet extends React.Component{
    componentDidMount(){
        window.location.href = 'https://www.coinbase.com/oauth/authorize?client_id=216aa17729ca94c3ef280a54ea11f0b6caaa7588bb11cd7535a7881851ccd525&redirect_uri=https%3A%2F%2Fkaios-dev.web.app%2FcoinbaseWallet&response_type=code&&scope=wallet:user:read,wallet:user:email,wallet:accounts:read,wallet:transactions:read,wallet:addresses:read';
        
    }

    render(){
        return(
            <div className={Style.wrapper}>
                <PageSpinner/>
            </div>
        )
    }
}


export default CoinbaseWallet;