import React, { useEffect, useRef } from 'react';
import Style from './webview.module.css';
import Header from 'components/Header/Header';
import { useParams } from 'react-router-dom';


const WebView = (props) => {
    const scrollRef = useRef();
    const { url } = useParams();
    const srcURl = decodeURIComponent(url);
    useEffect(() => {
        // scrollRef.current.scrollIntoView({
        //     behavior: 'smooth',
        //   });
        window.scrollTo({ top: 0, behavior: 'smooth' });
        document.addEventListener('keydown', onKeyDown);
        return () => { document.removeEventListener('keydown', onKeyDown); };
    }, []);


    const onKeyDown = (e) => {
        try {
            if (!e.key) {
                return;
            }
            if (e.key === 'ArrowUp') {
                window.scrollBy(0, -50);
            } else if (e.key === 'ArrowDown') {
                window.scrollBy(0, 50);
            } else if (e.key === 'Enter') {
            } else if (e.key === 'Backspace' || e.key === 'ArrowRight' ||  e.key === 'SoftRight') {
                props.history.goBack();
            }
        } catch (e) {
            console.log('error');
        }
    };

    return (
        <div className={Style.container}>
            <Header title='Web' back={true} backHandler={() => props.history.goBack()} />
            <iframe src={srcURl} title={'srcURl'} className={Style.iframeWindow} scrolling="yes" />
        </div>
    );
};

export default WebView;