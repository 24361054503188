import React from 'react';
import Style from '../../pages/discover/discover.module.css';

const ReadMoreText = ({ content, index, selectedIndex, handleIndex, length = 20, readLess, isFocus }) => {
  if (content.length < length) {
    return <p dangerouslySetInnerHTML={{ __html: content }}></p>;
  }

  return (
    <div>
      {readLess && selectedIndex !== index ?
        <span dangerouslySetInnerHTML={{ __html: content.slice(0, length) }}></span>
        :
        <span dangerouslySetInnerHTML={{ __html: content }}></span>
      }
      {
        readLess && selectedIndex !== index ?
          <span className={`${Style.toolButton} ${Style.toolBarText}`} style={{ cursor: 'pointer', backgroundColor: isFocus ? '#c4b5c9' : 'white' }} onClick={() => handleIndex(index)}>Read More</span>
          :
          <span className={`${Style.toolButton} ${Style.toolBarText}`} style={{ cursor: 'pointer', backgroundColor: isFocus ? '#c4b5c9' : 'white' }} onClick={() => handleIndex(-1)}>Read Less</span>
      }
    </div>
  );
};

export default ReadMoreText;