import React, { useEffect, useRef } from 'react';
import Style from 'components/InputBox/inputBoxStyles.module.css';


const InputBox = ({ placeholder, inputValue, inputChangeHandler, isFocus }) => {
    const inputRef = useRef(null);
    useEffect(() => {
        if (isFocus && inputRef !== null) {
            inputRef.current.focus();
        } else {
            inputRef.current.blur();
        }
    }, [isFocus]);
    return (
        <div className={Style.InputboxContainer}>
            <label className={Style.InputBoxLabel}>
                <input placeholder=" " className={Style.InputBox} value={inputValue} onChange={inputChangeHandler} style={{ border: '1px solid #c4b5c9' }} ref={inputRef} />
                <span className={Style.InputBoxSpan}>{placeholder}</span>
            </label>
        </div>
    );
};

export default InputBox;