import React, { useEffect, useState, useRef } from 'react';
import Style from './discover.module.css';
import { useHistory, useLocation } from 'react-router-dom';
import { auth } from 'utils/firebase';
import { get } from '../../api/api';
import Header from 'components/Header/Header';
import Navigation from 'navigation/navigation';
import PageSpinner from 'components/Spinner/PageSpinner';
import ReadMoreText from 'components/ReadMoreText/ReadMoreText';
import { useNavigation } from 'hooks/useNavigation';
import { shuffle } from 'utils/utils';
import { logEventDiscoverTab } from 'utils/analytics';
import defaultImage from 'assets/logo_short.png';

const Discover = () => {
    const history = useHistory();
    const location = useLocation();
    const [feed, setFeed] = useState([]);
    const [readLessIndexIds, setReadLessIndexIds] = useState([]);
    const user = auth.currentUser;
    const [activeIndex, setActiveIndex] = useState(0);
    const [selectedIndex, setSeeMoreIndex] = useState(-1);
    const [activeLinkStepper, setActiveLinkStepper] = useState(0);
    const currentTimestamp = Date.now();
    const scrollToMe = useRef();
    const [loading, setLoading] = useState(true);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const onPress = (feedObj) => {
        if (feedObj.type === 'podcast') {
            history.push(`/podcastdetail/${feedObj._id}?`);
        } else {
            history.push(`/webview/${encodeURIComponent(feedObj.url)}`);
        }
    };

    useEffect(() => {
        async function _fetchData() {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            setLoading(true);
            const feedsData = localStorage.getItem('feedsData');
            const feeds = feedsData ? JSON.parse(feedsData) : null;
            if (feeds) {
                setFeed(feeds);
            }
            try {
                const response = await get('/discovery');
                const { discovery } = response;
                if (discovery && discovery.length) {
                    const shuffledArray = shuffle(discovery);
                    setFeed(shuffledArray);
                    localStorage.setItem('feedsData', JSON.stringify(shuffledArray));
                    const arrReadLessIndexIds = Array.from(shuffledArray.keys());
                    setReadLessIndexIds(arrReadLessIndexIds);
                    const selectedFeed = shuffledArray[activeIndex];
                    current.cardLength = shuffledArray.length;
                    if (selectedFeed) {
                        current.selectedFeed = selectedFeed;
                    }
                    setNavigation(current);
                    setLoading(false);
                }
            } catch (error) {
                setErrorMessage(error || 'Error fetching discovery');
                setLoading(false);
                const feedsData = localStorage.getItem('feedsData');
                const feeds = feedsData ? JSON.parse(feedsData) : null;
                if (feeds) {
                    setFeed(feeds);
                    setErrorMessage('');
                }
            }
            logEventDiscoverTab(user);
        }
        _fetchData();
    }, []);

    const scrollToFeedCardById = (id) => {
        setTimeout(() => {
            const feedDivElement = document.getElementById(`feedCard-${id}`);
            if (feedDivElement) {
                window.scroll({
                    top: feedDivElement.offsetTop,
                    left: 0,
                    behavior: 'smooth'
                });
            }
        });
    };

    const [current, setNavigation] = useNavigation({
        onUpKey: () => {
            if (current.activeIndex <= 0 && current.activeLinkStepper <= 0) {
                return;
            }
            if (current.activeLinkStepper <= 1) {
                setActiveIndex((i) => (i > -2 ? i - 1 : i));
                setActiveLinkStepper(2);
            } else {
                setActiveLinkStepper(current.activeLinkStepper - 1);
            }
            scrollToFeedCardById(current.activeIndex);
        },
        onDownKey: (len) => {
            if (current.cardLength === current.activeIndex + 1 && current.activeLinkStepper >= 2) {
                return;
            }
            if (current.activeLinkStepper >= 2) {
                setActiveIndex((i) => (len - 1 > i ? i + 1 : i));
                setActiveLinkStepper(0);
            } else {
                setActiveLinkStepper(current.activeLinkStepper + 1);
            }
            scrollToFeedCardById(current.activeIndex);
        },
        onLeftKey: (len) => {
        },
        onRightKey: (len) => {
        },
        onEnterKey: () => {
            if (current.cardLength === 0) {
                return;
            }
            if (current.activeIndex >= 0) {
                if (current.activeLinkStepper === 1) {
                    const arrReadLessIndexIds = current.readLessIndexIds.includes(current.activeIndex)
                        ? current.readLessIndexIds.filter((item) => item !== current.activeIndex)
                        : [...current.readLessIndexIds, current.activeIndex];
                    setReadLessIndexIds(arrReadLessIndexIds);
                } else {
                    if (current.selectedFeed.type === 'podcast') {
                        history.push(`/podcastdetail/${current.selectedFeed._id}?`);
                    } else {
                        history.push(`/webview/${encodeURIComponent(current.selectedFeed.url)}`);
                    }
                }
            }
        },
        onBackspaceKey: (key) => {
            if (key === 'Backspace') {
                window.close();
            }
            return;
        },
        onShiftKey: (landscapeMode) => {
            goBack();
            return;
        },
        showExitConfirm: () => {
            window.close();
        },
    });

    useEffect(() => {
        async function _load() {
            current.activeIndex = activeIndex;
            current.activeLinkStepper = activeLinkStepper;
            current.readLessIndexIds = readLessIndexIds;
            if (activeLinkStepper >= 2) {
                const selectedFeed = feed[activeIndex];
                if (selectedFeed) {
                    current.selectedFeed = selectedFeed;
                }
            }
        }
        _load();
    }, [activeIndex, activeLinkStepper, current, feed, readLessIndexIds]);

    const goBack = () => {
        if (location.key) {
            history.goBack();
        }
    };

    const isReadLess = (activeIndex) => {
        return readLessIndexIds.includes(activeIndex);
    };

    const handleIndex = (value) => {
        setSeeMoreIndex(value);
    };

    const getImage = (discover) => {
        if (discover.thumbnailURL80x80) {
            return discover.thumbnailURL80x80;
        } else if (discover.imageURL) {
            return discover.imageURL;
        } else if (discover.image) {
            return discover.image;
        } else {
            return defaultImage;
        }
    };

    return (
        <div className={Style.container} ref={scrollToMe}>
            <div className={Style.discoverContainer}>
                <Header title='Discover' />
                {loading && <PageSpinner />}
            </div>
            <div className={Style.discoverContainer}>
                {successMessage.length ? <span style={{ marginTop: 10, color: 'green', fontSize: '0.8rem' }}>{successMessage}</span> : <></>}
                {errorMessage.length ? <span style={{ marginTop: 10, color: 'red', fontSize: '0.8rem' }}>{errorMessage}</span> : <></>}
                {!loading && feed.map((data, i) => (
                    <div id={`feedCard-${i}`} key={i} className={Style.feedContainer} style={{ border: (activeIndex === i) ? '1px solid purple' : '0px solid white' }}>
                        <img src={getImage(data)} alt='' className={Style.feedBanner} />
                        <div className={Style.bodySection}>
                            <div className={Style.feedTitle}>{data.title}</div>
                            <div className={Style.author}>{data.author}</div>
                            <div className={`${Style.feedDiscrption}`}>
                                <ReadMoreText content={data.description} index={i} selectedIndex={selectedIndex} handleIndex={handleIndex} length={20} readLess={isReadLess(i)} isFocus={(activeIndex === i) && activeLinkStepper === 1} />
                            </div>
                        </div>
                        <div className={Style.toolBar}>
                            <span className={`${Style.toolBarType} ${Style.toolBarText}`}>{data.type === 'podcast' ? 'Podcast' : 'News'}</span>
                            {/* <span className={`${Style.toolButton} ${Style.toolBarText}`} style={{ cursor: 'pointer', backgroundColor: (activeIndex === i) && activeLinkStepper === 2 ? '#c4b5c9' : 'white' }} onClick={(e) => onPress(data)}>{data.type === 'podcast' ? 'Listen' : 'Read Now'}</span> */}
                        </div>
                    </div>
                ))}
            </div>
            <div style={{ height: '50px' }} />
            <Navigation active="Discover" />
        </div>
    );
};

export default Discover;