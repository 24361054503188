import React, { Component } from 'react';
import { auth } from 'utils/firebase';
import { get, post } from '../../api/api';
import PageSpinner from 'components/Spinner/PageSpinner';
import MenuButton from 'components/MenuButton/MenuButton';
import Header from 'components/Header/Header';
import Style from 'pages/settings/settingsStyles.module.css';
import { colors } from 'utils/colors';

class SettingsPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: null,
            loading: true,
            activeTab: 0,
        };
    }

    async componentDidMount() {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        document.addEventListener('keydown', this.onKeyDown);
        await this.getUser();
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.onKeyDown);
    }

    getUser = async () => {
        try {
            const response = await get('/user/me');
            const { user } = response;
            if (user) {
                this.setState({ user: user, loading: false });
                await this.getData();
            }
        } catch (err) {
            this.setState({ loading: false });
        }
    };

    handleClickLogout = async () => {
        try {
            this.setState({ loading: true }, async () => {
                await post('/user/logout');
                auth.signOut();
                localStorage.removeItem('accessToken');
                this.setState({ loading: false });
                this.props.history.push('/');
            });
        } catch (err) {
            this.setState({ loading: false });
            auth.signOut();
            localStorage.removeItem('accessToken');
            this.props.history.push('/');
        }
    };

    handleClickTermsOfUse = () => {
        this.props.history.push('/home/termsAndConditions');
    };

    handleClickPrivacyPolicy = () => {
        this.props.history.push('/home/privacyPolicy');
    };

    backHandler = () => {
        this.props.history.push('/home/profile');
    };

    scrollToMenuButton = (id) => {
        setTimeout(() => {
            const menuButtonElement = document.getElementById(`menuButton-${id}`);
            if (menuButtonElement) {
                window.scroll({
                    top: menuButtonElement.offsetTop,
                    left: 0,
                    behavior: 'smooth',
                });
            }
        });
    };

    onKeyDown = (e) => {
        if (e.key === 'ArrowLeft' || e.key === 'SoftRight') {
            this.props.history.goBack();
        } else if (e.key && e.key === 'ArrowUp') {
            if (this.state.activeTab > 0) {
                this.setState((prevState) => {
                    setTimeout(() => {
                        this.scrollToMenuButton(prevState.activeTab - 1);
                    });
                    return { activeTab: prevState.activeTab - 1 };
                });
            } else {
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }
        } else if (e.key && e.key === 'ArrowDown') {
            if (this.state.activeTab < 3) {
                this.setState((prevState) => {
                    setTimeout(() => {
                        this.scrollToMenuButton(prevState.activeTab + 1);
                    });
                    return { activeTab: prevState.activeTab + 1 };
                });
            } else {
                window.scrollTo({ bottom: 0, behavior: 'smooth' });
            }
        } else if (e.key && e.key === 'Enter') {
            if (this.state.activeTab === 1) {
                this.handleClickTermsOfUse();
            } else if (this.state.activeTab === 3) {
                this.handleClickLogout();
            }
        } else if (e.key === 'Backspace') {
            this.props.history.goBack();
        }
    };

    render() {
        return (
            <>
                <Header title='Settings' back={true} backHandler={this.backHandler} />
                {this.state.loading ? (
                    <PageSpinner />
                ) : (
                    <div className={Style.container}>
                        {
                            this.state.user &&
                            <div className={Style.ProfileCard}>
                                <p className={Style.NameStyle}>{this.state.user.displayName}</p>
                                <p className={Style.EmailStyle}>{this.state.user.email}</p>
                            </div>
                        }
                        <h5 className={Style.CustomHeading}>About</h5>
                        <hr className={Style.HrStyle} />
                        <MenuButton
                            index={1}
                            buttonText='Terms and condition'
                            iconBgColor={colors.ICON_BACKGROUND_PURPLE}
                            iconColor={colors.ICON_PURPLE}
                            onClick={this.handleClickTermsOfUse}
                            isFocus={this.state.activeTab === 1}
                        >
                            <svg
                                stroke="currentColor"
                                fill="currentColor"
                                strokeWidth="0"
                                viewBox="0 0 24 24"
                                height="1.5em"
                                width="1.5em"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path d="M1 21h12v2H1zM5.245 8.07l2.83-2.827 14.14 14.142-2.828 2.828zM12.317 1l5.657 5.656-2.83 2.83-5.654-5.66zM3.825 9.485l5.657 5.657-2.828 2.828-5.657-5.657z"></path>
                            </svg>
                        </MenuButton>
                        <hr className={Style.HrStyle} />
                        <MenuButton
                            index={1}
                            buttonText='Privacy Policy'
                            iconBgColor={colors.ICON_BACKGROUND_PURPLE}
                            iconColor={colors.ICON_PURPLE}
                            onClick={this.handleClickPrivacyPolicy}
                            isFocus={this.state.activeTab === 2}
                        >
                            <svg
                                stroke="currentColor"
                                fill="currentColor"
                                strokeWidth="0"
                                viewBox="0 0 116.42 122.88"
                                height="1.5em"
                                width="1.5em"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path d="M88.08,60.79c10.53,6.67,20,9.83,28.22,9.09,1.42,28.85-9.23,45.89-28.11,53-18.24-6.66-29-23-28.23-53.44a40.39,40.39,0,0,0,28.12-8.65ZM62.9,12.13l20.72,19.4H62.9V12.13ZM21.27,56H63.14c2.74,0,3.1,3.75,1.32,5-.83.15-1.65.28-2.48.39H21.3c-3.22,0-3.22-5.33,0-5.38Zm0,16.74H50.46c.08,1.84.21,3.63.37,5.38H21.3c-3.24,0-3.2-5.38,0-5.38Zm0,16.74H52.48c.38,1.85.81,3.65,1.29,5.38H21.3c-3.24,0-3.2-5.38,0-5.38Zm0-50.22H45.36c3.24,0,3.2,5.37,0,5.37H21.3c-3.24,0-3.21-5.37,0-5.37Zm0-15.57H34.52c3.19,0,3.23,5.37,0,5.37H21.3c-3.24,0-3.2-5.37,0-5.37ZM95.15,34.32A3.44,3.44,0,0,0,92.64,31L62.12,1.27A3.43,3.43,0,0,0,59.45,0H6.2A6.2,6.2,0,0,0,0,6.2V112.35a6.21,6.21,0,0,0,6.2,6.2H66a51.93,51.93,0,0,1-5.08-6.93h-54V6.89H56V35a3.47,3.47,0,0,0,3.47,3.48H88.22V50.33q3.52,2.22,6.93,4.05V34.32ZM88.3,70.66c7.19,4.55,13.68,6.71,19.25,6.2,1,19.68-6.3,31.3-19.17,36.14a2.34,2.34,0,0,1-.24-.09V70.79l.16-.13Zm-.21-6.31c9.32,5.91,17.75,8.71,25,8.05,1.26,25.54-8.17,40.62-24.89,46.91C72,113.42,62.49,99,63.2,72a35.74,35.74,0,0,0,24.89-7.66Z" />
                            </svg>
                        </MenuButton>
                        <hr className={Style.HrStyle} />
                        <MenuButton
                            index={3}
                            buttonText={`App Version: ${process.env.REACT_APP_VERSION}`}
                            iconBgColor={colors.ICON_BACKGROUND_PURPLE}
                            iconColor={colors.ICON_PURPLE}
                            onClick={() => { }}
                            isFocus={this.state.activeTab === 3}
                            showArrow={false}
                            cursorStyle={'alias'}
                        >
                            <svg
                                stroke="currentColor"
                                fill="none"
                                strokeWidth="2"
                                viewBox="0 0 20 22"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                height="1.5em"
                                width="1.5em"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <g fill="none" fill-rule="evenodd" stroke="#000000" stroke-linecap="round" stroke-linejoin="round" transform="translate(2 4)">
                                    <path d="m.5 8.5 8 4 8.017-4" />
                                    <path d="m.5 4.657 8.008 3.843 8.009-3.843-8.009-4.157z" />
                                </g>
                            </svg>
                        </MenuButton>
                        <hr className={Style.HrStyle} />
                        <MenuButton
                            index={2}
                            buttonText='Logout'
                            iconBgColor={colors.ICON_BACKGROUND_PINK}
                            iconColor={colors.ICON_PINK}
                            onClick={this.handleClickLogout}
                            isFocus={this.state.activeTab === 4}
                        >
                            <svg
                                stroke="currentColor"
                                fill="none"
                                strokeWidth="2"
                                viewBox="0 0 24 24"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                height="1.5em"
                                width="1.5em"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                                <polyline points="16 17 21 12 16 7"></polyline>
                                <line x1="21" y1="12" x2="9" y2="12"></line>
                            </svg>
                        </MenuButton>
                    </div>
                )}
            </>
        );
    }
}

export default SettingsPage;
