import React, { useState, useEffect } from 'react';
import Header from 'components/Header/Header';
import PageSpinner from 'components/Spinner/PageSpinner';
import Cards from 'components/Cards/Cards';
import Styles from './airDropHistoryStyles.module.css';
import { auth, firestore } from 'utils/firebase';
import { collection, getDocs, query, limit } from 'firebase/firestore';

function AirDropHistoryPage(props) {
    const [airDrops, setAirDrops] = useState([]);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const querySnapshot = getDocs(query(collection(firestore, 'airdrop'), limit(10)));
        querySnapshot.then((doc) => {
            const airDropsList = [];
            doc.forEach((list) => {
                const data = list.data();
                data._id = list.id;
                if (data && data.token) { // Need Verification
                    airDropsList.push(data);
                    setLoading(false);
                } else {
                    setLoading(false);
                }
            });
            setAirDrops(airDropsList);
        });
    }, []);

    const backHandler = () => {
        props.history.push('/history');
    };

    return (
        <>
            <Header title='Air Drops History' back={true} backHandler={backHandler} />
            {/* <div className="container"> */}
            {loading && <PageSpinner />}
            {/* </div> */}

            {airDrops.length > 0 && airDrops.map((data, i) => (<Cards data={data} key={i} />))}

            {!loading && airDrops.length == 0 &&
                <div className={Styles.container}>
                    No Air Drops History Found
                </div>
            }
        </>
    );
}

export default AirDropHistoryPage;